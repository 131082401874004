import React, { useState, useEffect } from "react";
import "../style/Home.scss";
import company from "../assets/images/section-2.png";
import video from "../assets/images/section-1.png";
import DA from "../assets/images/ourservices/data-analytics.png";
import CS from "../assets/images/ourservices/cloud-solutions.png";
import IA from "../assets/images/ourservices/investment-advisory.png";
import RM from "../assets/images/ourservices/risk-management.png";
import IMG1 from "../assets/images/aws.png";
import IMG2 from "../assets/images/react.png";
import IMG3 from "../assets/images/nextjs.png";
import IMG4 from "../assets/images/vercel.png";
import Carousel from "../components/Carousel";
import Video from "../assets/Video/video.mp4";
import { motion } from "framer-motion";
function Home() {
  const [Data, setData] = useState("Default");
  return (
    <div className="home-container">
      <div id="Home" className="home-section-1">
        <div className="section-1-video">
          <video width="100%" height="auto" loop autoPlay muted preload="metadata">
            <source src={Video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="home-section-2">
        <div className="section-2-title">
          <h2>Engaging with great networks</h2>
        </div>
        <div className="section-2-content">
          <div className="img1">
            <img src={IMG1} />
          </div>
          <div className="img2">
            <img src={IMG2} />
          </div>
          <div className="img3">
            <img src={IMG3} />
          </div>
          <div className="img4">
            <img src={IMG4} />
          </div>
        </div>
      </div>
      <div className="home-section-3" id="Our-Services">
        <div className="section-3-title">
          <h2>Our Services</h2>
          <span>Innovative IT Solutions Tailored to Your Business</span>
        </div>
        <div className="section-3-content">
          <div className="section-3-card">
            <div className="card1">
              <img src={DA} />
            </div>
            <div className="card2">
              <img src={RM} />
            </div>
            <div className="card3">
              <img src={IA} />
            </div>
            <div className="card4">
              <img src={CS} />
            </div>
          </div>
        </div>
      </div>
      <Carousel />
      <div className="home-section-5">
        <div id="Why" className="about-RGS">
          {Data === "Default" && (
            <motion.div
              className="container"
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
            >
              <div className="about-title">
                <h2>About RGS</h2>
              </div>
              <div className="about-content">
                <span>
                  Radhana Global Solusi (RGS) is a leading IT advisory and
                  consulting firm dedicated to empowering businesses through
                  innovative technology solutions.
                </span>
                <br />
                <span>
                  Our mission is to help businesses thrive in the digital age by
                  providing strategic guidance and expert support across various
                  IT domains.
                </span>
              </div>
            </motion.div>
          )}
        </div>
        <div className="about-RGS">
          {Data === "Client" && (
            <motion.div
              className="container"
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
            >
              <div className="about-title">
                <h2>Client Centric</h2>
              </div>
              <div className="about-content">
                <span>
                  We prioritize understanding our clients' unique challenges and
                  goals to deliver customized solutions that drive tangible
                  results.
                </span>
              </div>
            </motion.div>
          )}
        </div>
        <div className="about-RGS">
          {Data === "Tech" && (
            <motion.div
              className="container"
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
            >
              <div className="about-title">
                <h2>Technology Excellence</h2>
              </div>
              <div className="about-content">
                <span>
                  Our team of experienced IT professionals stays updated with
                  the latest industry trends and best practices to ensure
                  top-notch service delivery.
                </span>
              </div>
            </motion.div>
          )}
        </div>
        <div className="about-RGS">
          {Data === "Collab" && (
            <motion.div
              className="container"
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
            >
              <div className="about-title">
                <h2>Collaborative Partnership</h2>
              </div>
              <div className="about-content">
                <span>
                  We believe in building long-term partnerships with our
                  clients, working closely with them to navigate technological
                  complexities and achieve sustainable growth.
                </span>
              </div>
            </motion.div>
          )}
        </div>
        <div className="about-RGS">
          {Data === "Innovation" && (
            <motion.div
              className="container"
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
            >
              <div className="about-title">
                <h2>Innovation Focus</h2>
              </div>
              <div className="about-content">
                <span>
                  Constant innovation is at the core of our approach, enabling
                  us to develop creative and effective solutions that propel our
                  clients' businesses forward.
                </span>
              </div>
            </motion.div>
          )}
        </div>

        <div className="client-centric">
          <button
            className={Data === "Client" ? "active" : ""}
            onClick={() => setData("Client")}
          >
            Client Centric
          </button>
        </div>
        <div className="technology">
          <button
            className={Data === "Tech" ? "active" : ""}
            onClick={() => setData("Tech")}
          >
            Technology Excellence
          </button>
        </div>
        <div className="collaborative">
          <button
            className={Data === "Collab" ? "active" : ""}
            onClick={() => setData("Collab")}
          >
            Collaborative Partnership
          </button>
        </div>
        <div className="innovation">
          <button
            className={Data === "Innovation" ? "active" : ""}
            onClick={() => setData("Innovation")}
          >
            Innovation Focus
          </button>
        </div>
        <div className="Email">
          <div className="section-6-left">
            <h3>Contact our client team now</h3>
          </div>
          <div className="section-6-right">
            <a className="email-btn" href="mailto:contact@radhanaglobalsolusi.com">Email</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
