import React, {useState} from 'react'
import '../style/Navbar.scss';
import Logo from '../assets/images/Logoasli.png';
function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const scrollToSection = (sectionId) => {
        toggleMenu(); // Close the menu when a section is clicked
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='navbar-container'>
            <div className='navbar-logo'>
                <img src={Logo} alt="Logo"/>
            </div>
            <div className='menu-icon' onClick={toggleMenu}>
                {/* Hamburger icon */}
                {isMenuOpen ? <span style={{fontSize:'40px'}}>×</span> : <span>☰</span>}
            </div>
            <div className={`navbar-content ${isMenuOpen ? 'open' : ''}`}>
                <div className='content'>
                    <span onClick={() => scrollToSection('Home')}>Home</span>
                    <span onClick={() => scrollToSection('Our-Services')}>Our Services</span>
                    <span onClick={() => scrollToSection('Why')}>Why Choose RSG</span>
                </div>
            </div>
        </div>
    );
}

export default Navbar;