import React from 'react';
import '../style/Footer.scss';

function Footer() {
  return (
    <div className='footer-container'>
      <h2>PT RADHANA GLOBAL SOLUSI, 2024</h2>
    </div>
  )
}

export default Footer