import React, { useState, useEffect } from 'react';
import '../style/Home.scss'
import { motion } from 'framer-motion'
const contents = [
    {
      imgSrc: 'images/Founder.png',
      text: '"Being part of RGS and its dedicated team is truly fulfilling. We empower our clients by providing engaging and enjoyable investment experiences, meeting the demands of modern investors with a range of choices and informative tools. Our journey is about making investing not just a process, but a rewarding journey for everyone involved."',
      role: 'Founder/CEO,',
      name: 'Ammar G',
    },
    {
      imgSrc: 'images/Commissioner.png',
      text: '“Working with our talented and forward-looking team at RGS is an absolute privilege. Our diversity of thought is our strength as we constantly challenge the status quo to better serve our clients. We aim to make the process of investing engaging and enjoyable, offering modern investors the choices and tools they deserve.”',
      role: 'Commissioner,',
      name: 'Rofi H',
    },
  ];

function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % contents.length); // Cycle through the contents
    }, 5000); // Change content every 2 seconds

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []); // Empty dependency array means this effect runs once on mount

  const currentContent = contents[currentIndex];

  return (
    <div className="home-section-4">
      <motion.div className="card-container"
      key={currentIndex}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }} 
      >
          <div className="card-top">
            <img src={currentContent.imgSrc} alt="" />
          </div>
          <div className="card-mid">
            <p>{currentContent.text}</p>
          </div>
          <div className="card-bottom">
            <div className="expertise">{currentContent.role}</div>
            <div className="name">{currentContent.name}</div>
          </div>
      </motion.div>
    </div>
  );
}

export default Carousel;
