import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './routes/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function App() {
  return (
    <>
    <Navbar/>
    <Router>
      <Routes>
        <Route path='/' element={<Home/>} />
      </Routes>
    </Router>
    <Footer/>
    </>
  );
}

export default App;
